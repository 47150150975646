import { useForm } from 'react-hook-form';
import Button from '../../components/button';
import styles from './voucher.module.css';
import logo from '../../assets/logo.png';
import { GenerateSubmission, GenerateVoucher } from '../../api/voucher';
import LoadingOverlay from 'react-loading-overlay';
import { useState } from 'react';
import { MultiSelect } from "react-multi-select-component";


const options = [
  { label: "Java 3", value: "Java3_Unproctored" },
  { label: "Java 1", value: "Java1_Unproctored" },
  { label: "Python 3", value: "Python3_Unproctored" },
  { label: "Python 1", value: "Python1_Unproctored" },
];

const Admin = () => {
  const [selected, setSelected] = useState([]);
  const { register, handleSubmit } = useForm();
  const { register: archive, handleSubmit: handlearchive } = useForm();

  const [loading, updateLoading] = useState(false);
  const onSubmit = (values) => {
    
    if (selected.length <= 0) {
        alert("Allow at least one exam")
    }else{
      updateLoading(true);
      values = {...values, examSelected: selected.map(o =>  o["value"])};
      GenerateVoucher(values).then(data => {
        // Create blob link to download
        var mystring = "Vouchers";
        data = data["data"];
        data["vouchers"].forEach(id => {
          mystring += "\n" + id;
        })
  
        var myblob = new Blob([mystring], {
          type: 'text/plain'
        });
  
        const url = window.URL.createObjectURL(
          new Blob([myblob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Vouchers.csv`,
        );
  
        // Append to html link element page
        document.body.appendChild(link);
  
        // Start download
        link.click();
  
        // Clean up and remove the link
        link.parentNode.removeChild(link);
  
        // console.log(data)
        updateLoading(false);
      }).catch(err => {
        updateLoading(false);
        console.log(err);
      })
    }

  };

  const onArchive = (values) => {
    updateLoading(true);
    // console.log(values);
    var file = values["file"][0];
    var reader = new FileReader();
    reader.onload = function (event) {
      var vouchers = event.target.result.split("\n");
      vouchers.shift();
      values["vouchers"] = vouchers;
      GenerateSubmission(values).then(data => {
        let url = data["data"]["file"];
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `submissions.zip`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        // console.log(data)
        updateLoading(false);
      }).catch(err => {
        updateLoading(false);
        console.log(err);
      });
    };

    reader.readAsText(file);
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text='Processing...'
    >
      <div className={styles.container}>
        <section className={styles.branding}>
          <img src={logo} alt="logo" className={styles.logo} />
        </section>
        <section className={styles.content}>
          <form className={styles.form} onSubmit={ handleSubmit(onSubmit)}>
            <h2 className={styles.formTitle}>Generate Vouchers</h2>
            <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Exams"
      />
            <label> 
              <span>Enter Your Password</span>
              <input type="text" placeholder="Password" {...register('pass', { required: true })}></input>
            </label>
            <label>
              <span>Exam Vouchers Ordered</span>
              <input type="text" placeholder="0" {...register('num', { required: true })}></input>
            </label>
            <label>
              <span>Notification</span>
              <input type="text" placeholder="a@gmail.com, b@gmail.com" {...register('subs', { required: false })}></input>
            </label>
            <label>
              <span>Proctor</span>
              <input type="text" placeholder="a@gmail.com, b@gmail.com" {...register('proctor', { required: false })}></input>
            </label>
            <label className="select">
                <span>Branding</span>
                <select placeholder="branding" {...register('branding', { required: true })} required>
                  <option value="default">Default</option>
                  <option value="hsbc">HSBC, English</option>
                  {/* <option value="hsbc-zh">HSBC, Chinese</option> */}
                </select>
              </label>
            {/* <label>
              <span>Special Note</span>
              <input type="text" placeholder="Add a note" {...register('note', { required: true })}></input>
            </label> */}
            <Button type="submit">Generate</Button>
          </form>
          <br />
          <form className={styles.form} onSubmit={handlearchive(onArchive)}>
            <h2 className={styles.formTitle}>Download Submissions</h2>
            <label>
              <span>Enter Your Password</span>
              <input type="text" placeholder="Password" {...archive('pass', { required: true })}></input>
            </label>
            <label>
              <span>CSV File</span>
              <input type="file"
                id="avatar" name="avatar"
                accept=".csv" {...archive('file', { required: true })} ></input>
            </label>
            {/* <label>
              <span>Special Note</span>
              <input type="text" placeholder="Add a note" {...register('note', { required: true })}></input>
            </label> */}
            <Button type="submit">Submit</Button>
          </form>
        </section>
      </div>
    </LoadingOverlay>
  );
};

export default Admin;
