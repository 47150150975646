const axios = require('axios')

const BASE_URL = "/dev"

export const GetVoucher = (voucheId) => {
    let opts = {
        method: 'GET',
        url: BASE_URL + `/voucher-details-get/${voucheId}`,
        headers: {
            'x-api-key': `${process.env.REACT_APP_API_KEY}`
        }
    }
    return axios(opts)
}

export const UpdateVoucher = (voucherId, data, key) => {
    let opts = {
        method: 'POST',
        url: BASE_URL + `/voucher-details`,
        headers: {
            'x-api-key': `${process.env.REACT_APP_API_KEY}`
        },
        data: {
            "voucher-id": voucherId,
            "key": key,
            "values": data
        }
    }
    return axios(opts)
}

export const GetStatus = (voucherId) => {
    let opts = {
        method: 'GET',
        url: BASE_URL + `/instance-state/${voucherId}`,
        headers: {
            'x-api-key': `${process.env.REACT_APP_API_KEY}`
        }
    }
    return axios(opts)
}

export const GenerateVoucher = (data) => {
    let opts = {
        method: 'POST',
        url: BASE_URL + `/voucher-generate`,
        headers: {
            'x-api-key': `${process.env.REACT_APP_API_KEY}`
        },
        data: data
    }
    return axios(opts)
}

export const GenerateSubmission = (data) => {
    let opts = {
        method: 'POST',
        url: BASE_URL + `/archive-submission`,
        headers: {
            'x-api-key': `${process.env.REACT_APP_API_KEY}`
        },
        data: data
    }
    return axios(opts)
}

export const GetDatabase = (data) => {
    let opts = {
        method: 'POST',
        url: BASE_URL + `/download-database`,
        headers: {
            'x-api-key': `${process.env.REACT_APP_API_KEY}`
        },
        data: data
    }
    return axios(opts)
}