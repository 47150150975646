import styles from './App.module.css';
import Voucher from './views/voucher';
import Admin from './views/admin';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Export from './views/export';

function App() {
  return (
    <div className={styles.App}>
      <Router>
        <Switch>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/export">
            <Export />
          </Route>
          <Route path="/">
            <Voucher />
          </Route>
        </Switch>
    </Router>
    </div>
  );
}

export default App;
