import { useForm } from 'react-hook-form';
import Button from '../../components/button';
import styles from './voucher.module.css';
import logo from '../../assets/logo.png';
import Countdown from 'react-countdown';
import { GetStatus, GetVoucher, UpdateVoucher } from '../../api/voucher';
import { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker";
import TimezoneSelect from 'react-timezone-select';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import "react-datepicker/dist/react-datepicker.css";
const Voucher = () => {
  const { register, handleSubmit } = useForm();
  const { register: createUser, handleSubmit: handleCreateUser } = useForm();
  const [voucher, updateVoucher] = useState(0);
  const [time, updateTime] = useState(null);
  const [userDetails, updateUserD] = useState(null);
  const [status, updateStatus] = useState(null);
  const [region, updateRegion] = useState("");
  const [ip, updateIP] = useState(null);
  const [loading, updateLoading] = useState(false);
  const [schedule, setschedule] = useState("now");
  const [startDate, setStartDate] = useState();
  const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [mobile, setMobile] = useState();
  const [allowedExams, setAllowedExams] = useState([]);
  const { register: createExam, handleSubmit: handleCreateExam } = useForm({
    defaultValues: {
      schedule: schedule
    }
  });
  const valueToNameMap = {"Java3_Unproctored": "Java 3", "Java1_Unproctored": "Java 1", "Python3_Unproctored":"Python 3", "Python1_Unproctored":"Python 1"};
  const Completionist = ({ ip, voucher, port }) => <a target="_blank" rel="noreferrer" href={`http://${ip}:${port}/guacamole/#/?username=${voucher}&password=1seleke8`}> Start Exam</a>;
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      if (ip) {
        var port = 8002;
        var add = "ec2-";
        add += ip.split(".").join('-');
        add += "."+region
        add += ".compute.amazonaws.com"
        if(region === "cn-north-1" || region === "cn-northwest-1"){
          add += ".cn"
        }
        return <Completionist ip={add} voucher={voucher} port={port} />;
      } else if (status === "terminated") {
        return <span>Exam has been ended.</span>;
      } else {
        GetStatus(voucher).then(data => {
          updateStatus(data["data"]["status"]);
          updateRegion(data["data"]["region"])
          updateIP(data["data"]["ip"])
        }).catch(err => {
          console.log(err);
          return <span> Try Again </span>;
        })
        return <span> Exam is initiating </span>;
      }
    } else {
      // Render a countdown
      return <span>Exam will be ready in {hours}:{minutes}:{seconds} <br /> Start time: {new Date(time).toLocaleString()}<br/> We will also send an e-mail once the exam is ready.</span>;
    }
  };
  const ul = (inp) => {
    if (inp === "12") {
      return "00";
    }
    if (inp.length === 1) {
      return "0" + inp;
    } else {
      return inp;
    }
  }
  const compareTime = (big, small, sD, dT) => {
    let bigO = big.split(" ");
    let bigT = bigO[0].split(":");
    let smallO = small.split(" ");
    let smallT = smallO[0].split(":");
    if (!sD || new Date(sD).getTime() === new Date(dT).getTime()) {
      if (bigO[1] > smallO[1]) {
        return true;
      } else if (bigO[1] < smallO[1]) {
        return false;
      }
      if (ul(bigT[0]) > ul(smallT[0]) || (ul(bigT[0]) === ul(smallT[0]) && ul(bigT[1]) > ul(smallT[1]))) {
        return true;
      }
    }
    return false;
  }
  const onSubmit = (values) => {
    updateLoading(true);
    GetVoucher(values["voucher"]).then(data => {
      if(data.data["examSelected"] && data.data["examSelected"].length >0){
        setAllowedExams(data.data["examSelected"])
      }
      updateVoucher(values["voucher"]);
      if (data.data["userDetails"]) {
        updateUserD(true);
      }
      if (data.data["examTime"]) {
        updateTime(data.data["examTime"]);
      }
      updateLoading(false);
    }).catch(err => {
      updateLoading(false);
      console.log(err);
    })
  };
  const onCreateUser = (values) => {
    if (!mobile) {
      alert("Please enter mobile number");
    }
    values["mobile"] = mobile;
    updateLoading(true);
    UpdateVoucher(voucher, values, "user_details").then(data => {
      updateUserD(true);
      updateLoading(false);
    }).catch(err => {
      updateLoading(false);
      console.log(err);
    })
  };

  const onCreateExam = (values) => {
    updateLoading(true);
    if (values["schedule"] === "now") {
      let utc = new Date();
      utc.setMinutes(utc.getMinutes() + 5);
      values["examTime"] = utc.toUTCString();
      UpdateVoucher(voucher, values, "exam_details").then(data => {
        updateTime(data.data["examTime"]);
        updateLoading(false);
      }).catch(err => {
        updateLoading(false);
        console.log(err);
      })
    } else {
      let d = new Date(startDate);
      let offsetR = typeof (selectedTimezone) == "string" ? (-d.getTimezoneOffset()) / 60 : selectedTimezone.offset;
      let offset = Math.abs(offsetR).toString();
      let offsetsplit = offset.split(".");
      let hour = (values["time"] / 2).toString().split(".");
      let time = `${hour[0]}:${hour[1] ? hour[1] * 6 : "00"}:00`;
      let newHour = hour[0] >= 12 ? hour[0] - 12 : hour[0];
      let lastTag = hour[0] >= 12 ? "PM" : "AM";
      // console.log(dtTime, `${newHour}:${hour[1] ? hour[1] * 6 : "00"}:00 ${lastTag}`, startDate, dtDate)
      let dt = new Date();
      let stz = typeof (selectedTimezone) == "string" ? selectedTimezone : selectedTimezone.value;
      dt.setMinutes(dt.getMinutes() + 10);
      let dtDate = dt.toLocaleDateString("en-US", { timeZone: stz })
      let dtTime = dt.toLocaleTimeString("en-US", { timeZone: stz })
      if (compareTime(dtTime, `${newHour}:${hour[1] ? hour[1] * 6 : "00"}:00 ${lastTag}`, startDate, dtDate)) {
        alert("Select valid time");
        updateLoading(false);
      } else {
        // console.log(`${d.toDateString()} ${time} GMT${offsetR > 0 ? "+" : "-"}${offsetsplit[0]}${offsetsplit[1] ? offsetsplit[1] * 6 : "00"}`);
        let utc = new Date(`${d.toDateString()} ${time} GMT${offsetR > 0 ? "+" : "-"}${offsetsplit[0]}${offsetsplit[1] ? offsetsplit[1] * 6 : "00"}`);
        values["examTime"] = utc.toUTCString();
        utc.setMinutes(utc.getMinutes() - 6);
        values["utcDate"] = utc.getUTCDate();
        values["utcYear"] = utc.getUTCFullYear();
        values["utcMonth"] = utc.getUTCMonth() + 1;
        values["utcHour"] = utc.getUTCHours();
        values["utcMinute"] = utc.getUTCMinutes();
        // console.log(values);
        UpdateVoucher(voucher, values, "exam_details").then(data => {
          updateTime(data.data["examTime"]);
          updateLoading(false);
        }).catch(err => {
          updateLoading(false);
          console.log(err);
        })
      }
    }
  };

  let dt = new Date();
  let stz = typeof (selectedTimezone) == "string" ? selectedTimezone : selectedTimezone.value;
  dt.setMinutes(dt.getMinutes() + 10);
  let dtDate = dt.toLocaleDateString("en-US", { timeZone: stz })
  let dtTime = dt.toLocaleTimeString("en-US", { timeZone: stz })
  if (!startDate) {
    setStartDate(new Date(dtDate));
  }
  if (startDate < new Date(dtDate)) {
    setStartDate(new Date(dtDate));
  }
  return (
    <LoadingOverlay
      active={loading}
      spinner
      text='Processing...'
    >
      <div className={styles.container}>
        <section className={styles.branding}>
          <img src={logo} alt="logo" className={styles.logo} />
        </section>
        {!voucher && (
          <section className={styles.content}>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <h2 className={styles.formTitle}>Start Your Exam</h2>
              <label>
                <span>Voucher Number</span>
                <input type="text" placeholder="Test12" {...register('voucher', { required: true })}></input>
              </label>
              <Button type="submit">Start Exam</Button>
            </form>
          </section>
        )}

        {!!(voucher && voucher !== "" && !userDetails) && (
          <section className={styles.content}>
            <form className={styles.form} onSubmit={handleCreateUser(onCreateUser)}>
              <h2 className={styles.formTitle}>Basic Details</h2>
              <label>
                <span>Name</span>
                <input type="text" placeholder="Name" {...createUser('name', { required: true })} required></input>
              </label>
              <label>
                <span>Email</span>
                <input type="email" pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$' placeholder="E-mail" {...createUser('email', { required: true })} required></input>
              </label>
              <label>
                <PhoneInput
                inputStyle={{width:"273px"}}
                  inputProps={{
                    required: true
                  }}
                  country={'us'}
                  value={mobile}
                  onChange={phone => setMobile(phone)}
                />
              </label>
              <label>
                <span>City</span>
                <input type="text" placeholder="City" {...createUser('city', { required: true })} required></input>
              </label>
              <label>
                <span>Institution Name</span>
                <input type="text" placeholder="Institution Name" {...createUser('iname', { required: true })} required></input>
              </label>
              <label className="select">
                <span>Category</span>
                <select placeholder="Category" {...createUser('job', { required: true })} required>
                  <option value="student">Student</option>
                  <option value="professional">Professional</option>
                </select>
              </label>
              <label>
                <span>Programming Experience</span>
                <input type="number" placeholder="In Years" {...createUser('pexp', { required: true })} required></input>
              </label>
              <label>
                <span>Current Company Experience</span>
                <input type="number" placeholder="In Years" {...createUser('cexp', { required: true })} required></input>
              </label>
              <Button type="submit">Next</Button>
            </form>
          </section>
        )}

        {!!(voucher && voucher !== "" && userDetails && !time) && (

          <section className={styles.content}>
            <form className={styles.form} onSubmit={handleCreateExam(onCreateExam)}>
              <h2 className={styles.formTitle}>Exam Details</h2>
              <label className="select">
                <span>Exam Type</span>
                
                  {!!(allowedExams.length === 0) && (
                    <select type="text" placeholder="Select Exam Type" {...createExam('type', { required: true })} required>
                    <option value="Java3_Unproctored">Java 3</option>
                  <option value="Java1_Unproctored">Java 1</option>
                  <option value="Python3_Unproctored">Python 3</option>
                  <option value="Python1_Unproctored">Python 1</option>
                  </select>
                  )}

{!!(allowedExams.length !== 0) && (
                    <select type="text" placeholder="Select Exam Type" {...createExam('type', { required: true })} required>
                      {allowedExams.map(exam => <option value={exam}>{valueToNameMap[exam]}</option>)}
                  </select>
                  )}
                  
              </label>
              {/* <label className="select">
                <span>Language</span>
                <select type="text" placeholder="Select Exam Language" {...createExam('lang', { required: true })} required>
                  <option value="en">English</option>
                  <option value="zh">Chinese</option>
                </select>
              </label> */}
              <label className="select">
                <span>Region</span>
                <select placeholder="Region" {...createExam('region', { required: true })} required>
                  <option value="ap-east-1">Hong Kong</option>
                  {/* <option value="ap-south-1">India</option>
                  <option value="cn-north-1">China - Beijing</option>
                  <option value="cn-northwest-1">China - Ningxia</option> */}
                </select>
              </label>
              <label onChange={(evt) => setschedule(evt.target.value)} className="select">
                <span>Schedule</span>
                <select placeholder="Time"  {...createExam('schedule', { required: true })} required>
                  <option value="now">Launch Now</option>
                  <option value="schedule">Schedule</option>
                </select>
              </label>
              {!!(schedule === "schedule") && (
                <>
                  <label>
                    <span>Timezone</span>
                    <div className="timezone">
                      <TimezoneSelect
                        value={selectedTimezone}
                        onChange={setSelectedTimezone}
                      />
                    </div>
                  </label>
                  <label>
                    <span>Date</span>
                    <DatePicker minDate={new Date(dtDate)}
                      selected={startDate} onChange={(date) => setStartDate(date)} required />
                  </label>
                  <label className="select">
                    <span>Time</span>
                    <select placeholder="Region" {...createExam('time', { required: true })} required>
                      <option disabled={compareTime(dtTime, "00:00 AM", startDate, dtDate)} value="0">12:00 AM</option>
                      <option disabled={compareTime(dtTime, "00:30 AM", startDate, dtDate)} value="1">12:30 AM</option>
                      <option disabled={compareTime(dtTime, "1:00 AM", startDate, dtDate)} value="2">1:00 AM</option>
                      <option disabled={compareTime(dtTime, "1:30 AM", startDate, dtDate)} value="3">1:30 AM</option>
                      <option disabled={compareTime(dtTime, "2:00 AM", startDate, dtDate)} value="4">2:00 AM</option>
                      <option disabled={compareTime(dtTime, "2:30 AM", startDate, dtDate)} value="5">2:30 AM</option>
                      <option disabled={compareTime(dtTime, "3:00 AM", startDate, dtDate)} value="6">3:00 AM</option>
                      <option disabled={compareTime(dtTime, "3:30 AM", startDate, dtDate)} value="7">3:30 AM</option>
                      <option disabled={compareTime(dtTime, "4:00 AM", startDate, dtDate)} value="8">4:00 AM</option>
                      <option disabled={compareTime(dtTime, "4:30 AM", startDate, dtDate)} value="9">4:30 AM</option>
                      <option disabled={compareTime(dtTime, "5:00 AM", startDate, dtDate)} value="10">5:00 AM</option>
                      <option disabled={compareTime(dtTime, "5:30 AM", startDate, dtDate)} value="11">5:30 AM</option>
                      <option disabled={compareTime(dtTime, "6:00 AM", startDate, dtDate)} value="12">6:00 AM</option>
                      <option disabled={compareTime(dtTime, "6:30 AM", startDate, dtDate)} value="13">6:30 AM</option>
                      <option disabled={compareTime(dtTime, "7:00 AM", startDate, dtDate)} value="14">7:00 AM</option>
                      <option disabled={compareTime(dtTime, "7:30 AM", startDate, dtDate)} value="15">7:30 AM</option>
                      <option disabled={compareTime(dtTime, "8:00 AM", startDate, dtDate)} value="16">8:00 AM</option>
                      <option disabled={compareTime(dtTime, "8:30 AM", startDate, dtDate)} value="17">8:30 AM</option>
                      <option disabled={compareTime(dtTime, "9:00 AM", startDate, dtDate)} value="18">9:00 AM</option>
                      <option disabled={compareTime(dtTime, "9:30 AM", startDate, dtDate)} value="19">9:30 AM</option>
                      <option disabled={compareTime(dtTime, "10:00 AM", startDate, dtDate)} value="20">10:00 AM</option>
                      <option disabled={compareTime(dtTime, "10:30 AM", startDate, dtDate)} value="21">10:30 AM</option>
                      <option disabled={compareTime(dtTime, "11:00 AM", startDate, dtDate)} value="22">11:00 AM</option>
                      <option disabled={compareTime(dtTime, "11:30 AM", startDate, dtDate)} value="23">11:30 AM</option>
                      <option disabled={compareTime(dtTime, "00:00 PM", startDate, dtDate)} value="24">12:00 PM</option>
                      <option disabled={compareTime(dtTime, "00:30 PM", startDate, dtDate)} value="25">12:30 PM</option>
                      <option disabled={compareTime(dtTime, "1:00 PM", startDate, dtDate)} value="26">1:00 PM</option>
                      <option disabled={compareTime(dtTime, "1:30 PM", startDate, dtDate)} value="27">1:30 PM</option>
                      <option disabled={compareTime(dtTime, "2:00 PM", startDate, dtDate)} value="28">2:00 PM</option>
                      <option disabled={compareTime(dtTime, "2:30 PM", startDate, dtDate)} value="29">2:30 PM</option>
                      <option disabled={compareTime(dtTime, "3:00 PM", startDate, dtDate)} value="30">3:00 PM</option>
                      <option disabled={compareTime(dtTime, "3:30 PM", startDate, dtDate)} value="31">3:30 PM</option>
                      <option disabled={compareTime(dtTime, "4:00 PM", startDate, dtDate)} value="32">4:00 PM</option>
                      <option disabled={compareTime(dtTime, "4:30 PM", startDate, dtDate)} value="33">4:30 PM</option>
                      <option disabled={compareTime(dtTime, "5:00 PM", startDate, dtDate)} value="34">5:00 PM</option>
                      <option disabled={compareTime(dtTime, "5:30 PM", startDate, dtDate)} value="35">5:30 PM</option>
                      <option disabled={compareTime(dtTime, "6:00 PM", startDate, dtDate)} value="36">6:00 PM</option>
                      <option disabled={compareTime(dtTime, "6:30 PM", startDate, dtDate)} value="37">6:30 PM</option>
                      <option disabled={compareTime(dtTime, "7:00 PM", startDate, dtDate)} value="38">7:00 PM</option>
                      <option disabled={compareTime(dtTime, "7:30 PM", startDate, dtDate)} value="39">7:30 PM</option>
                      <option disabled={compareTime(dtTime, "8:00 PM", startDate, dtDate)} value="40">8:00 PM</option>
                      <option disabled={compareTime(dtTime, "8:30 PM", startDate, dtDate)} value="41">8:30 PM</option>
                      <option disabled={compareTime(dtTime, "9:00 PM", startDate, dtDate)} value="42">9:00 PM</option>
                      <option disabled={compareTime(dtTime, "9:30 PM", startDate, dtDate)} value="43">9:30 PM</option>
                      <option disabled={compareTime(dtTime, "10:00 PM", startDate, dtDate)} value="44">10:00 PM</option>
                      <option disabled={compareTime(dtTime, "10:30 PM", startDate, dtDate)} value="45">10:30 PM</option>
                      <option disabled={compareTime(dtTime, "11:00 PM", startDate, dtDate)} value="46">11:00 PM</option>
                      <option disabled={compareTime(dtTime, "11:30 PM", startDate, dtDate)} value="47">11:30 PM</option>
                    </select>
                  </label>
                </>
              )}
              <Button type="submit">SUBMIT</Button>
            </form>
          </section>
        )}
        {!!(time && voucher && voucher !== "") && (
          <section className={styles.content}>
            <Countdown
              date={new Date(time)}
              renderer={renderer}
            />
          </section>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default Voucher;
