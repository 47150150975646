import { useForm } from 'react-hook-form';
import Button from '../../components/button';
import styles from './voucher.module.css';
import logo from '../../assets/logo.png';
import { GetDatabase } from '../../api/voucher';
import LoadingOverlay from 'react-loading-overlay';
import { useState } from 'react';
import CsvDownload from 'react-json-to-csv'

const Export = () => {
  const { register, handleSubmit } = useForm();

  const [loading, updateLoading] = useState(false);
  const [csvData, updatecsv] = useState(null);
  const onSubmit = (values) => {
    updateLoading(true);
    GetDatabase(values).then(data => {
      updateLoading(false)
      var output = data.data["Items"].map(function (inObj) {
        // console.log(inObj);
        return {
          "Voucher": inObj.voucher_id,
          "Name": !inObj.user_details ? "" : inObj.user_details["name"],
          "Email": !inObj.user_details ? "" : inObj.user_details["email"],
          "Years in Company": !inObj.user_details ? "" : inObj.user_details["cexp"],
          "Work Experience": !inObj.user_details ? "" : inObj.user_details["pexp"],
          "Institute Name": !inObj.user_details ? "" : inObj.user_details["iname"],
          "Mobile": !inObj.user_details ? "" : inObj.user_details["mobile"],
          "City": !inObj.user_details ? "" : inObj.user_details["city"],
          "Job": !inObj.user_details ? "" : inObj.user_details["job"],
          "Exam": !inObj.exam_details ? "" : inObj.exam_details["type"],
          "Exam-Time": !inObj.exam_details ? "" : inObj.exam_details["examTime"],
          "Exam-Region": !inObj.exam_details ? "" : inObj.exam_details["region"],

        }
      });
      updatecsv(output);
    }).catch(err => {
      updateLoading(false);
      console.log(err);
    })
  }
  return (
    <LoadingOverlay
      active={loading}
      spinner
      text='Processing...'
    >
      <div className={styles.container}>
        <section className={styles.branding}>
          <img src={logo} alt="logo" className={styles.logo} />
        </section>
        <section className={styles.content}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <h2 className={styles.formTitle}>Export Database</h2>
            <label>
              <span>Unique Pass</span>
              <input type="text" placeholder="Your unique pass" {...register('pass', { required: true })}></input>
            </label>

            {/* <label>
              <span>Special Note</span>
              <input type="text" placeholder="Add a note" {...register('note', { required: true })}></input>
            </label> */}
            <Button type="submit">Export</Button>
            {csvData && csvData !== "" && (<CsvDownload data={csvData} />)}
          </form>
        </section>
      </div>
    </LoadingOverlay>
  );
};

export default Export;
